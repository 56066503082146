
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'mapPopover',
  props: {
    location: {
      type: String,
    },
    adminLevels: {
      type: String,
    },
    surveyDate: {
      type: String,
    },
    prevSurveys: {
      type: Number,
    },
    isOpen: {
      type: Boolean,
    },
    mapId: {
      type: Number,
    },
  },
  emits: ['remove-info', 'mouseover-info', 'click-filter'],
});
