import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mapPopover = _resolveComponent("mapPopover")!
  const _component_GMapMarker = _resolveComponent("GMapMarker")!
  const _component_GMapMap = _resolveComponent("GMapMap")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_GMapMap, {
      options: _ctx.mapOptions,
      center: _ctx.position,
      zoom: _ctx.zoomLevel,
      "map-type-id": "terrain",
      ref: "mapRef",
      onDragend: _ctx.showNotif,
      class: "surveys-map-anthro"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.markers, (m, index) => {
          return (_openBlock(), _createBlock(_component_GMapMarker, {
            key: index,
            position: { lat: m.latitude, lng: m.longitude },
            draggable: false,
            icon: _ctx.markerMaker(m),
            onMouseover: ($event: any) => (_ctx.markerHovered(m.id)),
            onMouseout: _ctx.mouseLeave
          }, {
            default: _withCtx(() => [
              _createVNode(_component_mapPopover, {
                location: _ctx.parseLocationName(m),
                adminLevels: _ctx.parseAdminLevels(m),
                surveyDate: `${_ctx.parseMonth(m.surveyMonth)}, ${m.surveyYear}`,
                prevSurveys: m.previousSurveys,
                isOpen: m.id === this.openMarker,
                onRemoveInfo: _ctx.removeInfo,
                onMouseoverInfo: _cache[0] || (_cache[0] = ($event: any) => (_ctx.mouseDown = true)),
                mapId: m.id,
                onClickFilter: _ctx.filterLocation
              }, null, 8, ["location", "adminLevels", "surveyDate", "prevSurveys", "isOpen", "onRemoveInfo", "mapId", "onClickFilter"])
            ]),
            _: 2
          }, 1032, ["position", "icon", "onMouseover", "onMouseout"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["options", "center", "zoom", "onDragend"])
  ]))
}