
/* global google */
/* eslint-disable */

import { defineComponent, PropType, watch, ref, reactive } from 'vue';
import { minBy, maxBy } from 'lodash';
import mapPopover from '@/components/mapPopover/index.vue';
import dayjs from 'dayjs';
import { DEFAULT_MAP_OPTIONS, regions } from '@/utils/map';
import { surveysSubProps } from '@/utils/request';
// control svgs
import blackCircle from '@/assets/svg/circle.svg';
import blackTriangle from '@/assets/svg/triangle.svg';
import blackPlus from '@/assets/svg/plus.svg';
import blackMinus from '@/assets/svg/minus.svg';
import caretDown from '@/assets/svg/caret-down.svg';
import caretUp from '@/assets/svg/caret-up.svg';


export default defineComponent({
  name: 'MapResults',
  components: {
    mapPopover,
  },
  props: {
    markers: {
      type: [] as PropType<surveysSubProps[]>,
      require: true,
    },
    markerMaker: {
      type: Function,
      require: true,
    },
    region: {
      type: String,
      require: false,
    },
    isFiltered: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  setup() {
    const mapRef = ref();
    const zoomLevel = ref(3);
    const position = reactive({
        lat: -3.8333,
        lng: 29.3677,
    });
    watch(mapRef, (googleMap) => {
      if (googleMap) {
        googleMap.$mapPromise.then((map:any) => {
          const legendUI = document.createElement('div');
          //triangle
          const triangleMessage = document.createElement('span');
          triangleMessage.classList.add('surveys-legend-title');
          triangleMessage.innerText = "National Survey";
          legendUI.classList.add('surveys-legend');
          const triangle = new Image();
          triangle.src = blackTriangle;
          triangle.classList.add('surveys-legend-icon')
          legendUI.appendChild(triangle);
          legendUI.appendChild(triangleMessage);
          //space
          const space = document.createElement('br');
          legendUI.appendChild(space);
          //circle
          const circleMessage = document.createElement('span');
          circleMessage.classList.add('surveys-legend-title');
          circleMessage.innerText = "Sub-National Survey";
          legendUI.classList.add('surveys-legend');
          const circle = new Image();
          circle.src = blackCircle;
          circle.classList.add('surveys-legend-icon')
          legendUI.appendChild(circle);
          legendUI.appendChild(circleMessage);
          //divider
          const divider = document.createElement('div');
          divider.classList.add('surveys-legend-divider');
          legendUI.appendChild(divider);
          //plus
          const plusMessage = document.createElement('span');
          plusMessage.classList.add('surveys-legend-title');
          plusMessage.innerText = "Multiple Surveys";
          legendUI.classList.add('surveys-legend');
          const plus = new Image();
          plus.src = blackPlus;
          plus.classList.add('surveys-legend-icon')
          legendUI.appendChild(plus);
          legendUI.appendChild(plusMessage);
          //description
          const desc = document.createElement('p');
          desc.innerText = "Colours of points with multiple surveys represent the latest survey conducted in that location."
          desc.classList.add('surveys-legend-desc');
          legendUI.appendChild(desc);

          //re-center trigger
          const recenterNotification = document.createElement('div');
          recenterNotification.innerText = "Map view has changed from the selected filters, click to re-center.";
          recenterNotification.classList.add('surveys-map-notif');
          recenterNotification.setAttribute('id', 'mapNotif');
          recenterNotification.style.display = 'none';

          // map controls
          const controlUI = document.createElement('div');
          controlUI.classList.add('surveys-map-controls');
          const controlPlusContainer = document.createElement('div');
          controlPlusContainer.style.width = '100%';
          const controlPlus = new Image(12);
          controlPlus.src = blackPlus;
          controlPlusContainer.appendChild(controlPlus);
          controlPlusContainer.addEventListener('click', () => {
            zoomLevel.value += 1;
          });
          const controlMinusContainer = document.createElement('div');
          controlMinusContainer.style.width = '100%';
          const controlMinus = new Image();
          controlMinus.src = blackMinus;
          controlMinusContainer.appendChild(controlMinus);
          controlMinusContainer.addEventListener('click', () => {
            zoomLevel.value -= 1;
          });
          const navIcon = document.createElement('div');
          navIcon.classList.add('surveys-caret-controls');
          const controlCaretDown = new Image();
          controlCaretDown.src = caretDown;
          const controlCaretUp = new Image();
          controlCaretUp.src = caretUp;
          navIcon.appendChild(controlCaretUp);
          navIcon.appendChild(document.createElement('br'));
          navIcon.appendChild(controlCaretDown);
          navIcon.addEventListener('click', () => {
            map.setCenter(position);
            recenterNotification.style.display = 'none';
          });
          controlUI.appendChild(controlPlusContainer);
          const firstBr = document.createElement('br');
          controlUI.appendChild(firstBr);
          firstBr.classList.add('surveys-br');
          controlUI.appendChild(controlMinusContainer);
          const secondBr = document.createElement('br');
          secondBr.classList.add('surveys-br');
          controlUI.appendChild(secondBr);
          controlUI.appendChild(navIcon);
          // @ts-expect-error
          map.controls[google.maps.ControlPosition.TOP_CENTER].push(recenterNotification);
          //@ts-expect-error
          map.controls[google.maps.ControlPosition.RIGHT_TOP].push(controlUI);
          // @ts-expect-error
          map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legendUI);
        });
      }
    });
    return {
      mapRef,
      zoomLevel,
      position,
    };
  },
  data() {
    return {
      mouseDown: false,
      openMarker: null as unknown,
      markerChange: false,
    };
  },
  computed: {
    mapOptions() {
      const mapOptions = { ...DEFAULT_MAP_OPTIONS };
      mapOptions.draggable = true;
      mapOptions.gestureHandling = 'cooperative';
      return mapOptions;
    },
  },
  methods: {
    parseMonth(month: number | undefined):string {
      let m;
      if (month) {
        m = dayjs().month(month - 1).format('MMMM');
      } else {
        m = dayjs().format('MMMM');
      }
      return m;
    },
    parseLocationName(marker:surveysSubProps) {
      return marker.geographicAreaName ;
    },
    parseAdminLevels(marker:surveysSubProps) {
      let l = '';
      const adminArrays = [];
      if(marker.administrative_area_level_3) {
        adminArrays.push(marker.administrative_area_level_3)
      }
      if(marker.administrative_area_level_2) {
        adminArrays.push(marker.administrative_area_level_2)
      }
      if(marker.administrative_area_level_1) {
        adminArrays.push(marker.administrative_area_level_1)
      }
      return adminArrays.length > 0 ? adminArrays.join(', ') : 'N/A';
    },
    markerHovered(id: number) {
      this.openMarker = id;
    },
    removeInfo() {
      this.openMarker = null;
      this.mouseDown = false;
    },
    zoomIn() {
      this.zoomLevel += 1;
    },
    zoomOut() {
      this.zoomLevel -= 1;
    },
    filterLocation(id:number) {
      if (this.$props.markers) {
        const clickedMarker = this.$props.markers.filter((marker:surveysSubProps) => marker.id === id);
        this.$emit('popover-click', clickedMarker[0]);
        this.removeInfo();
      }
    },
    showNotif() {
      if (this.isFiltered) {
        const recenterNotification = document.getElementById('mapNotif');
        if (recenterNotification) {
          recenterNotification.addEventListener('click', () => {
            this.mapRef.$mapPromise.then((map:any) => {
              map.setCenter(this.position);
              recenterNotification.style.display = 'none';
            });
          });
          recenterNotification.style.display = 'block';
        }
      }
    },
    async mouseLeave() {
      setTimeout(() => {
        if (!this.mouseDown && !this.markerChange) {
          this.openMarker = null;
          this.markerChange = false;
        } else {
          this.mouseDown = false;
          this.markerChange = false;
        }
      }, 200);
    },
    async updateCenter() {
      if (this.markers) {
        if (this.markers.length > 1) {
          const locations = this.markers.map((item) => ({ latitude: item.latitude, longitude: item.longitude }));
          const minLat = minBy(locations, 'latitude')?.latitude || 0;
          const minLng = minBy(locations, 'longitude')?.longitude || 0;
          const maxLat = maxBy(locations, 'latitude')?.latitude || 0;
          const maxLng = maxBy(locations, 'longitude')?.longitude || 0;
          this.mapRef.fitBounds({
            north: maxLat, west: minLng,
            south: minLat, east: maxLng
          }, 50);
        } else {
          this.zoomLevel = 4;
          this.position = { lat: this.markers[0].latitude, lng: this.markers[0].longitude };
        }
      }
    }
  },
  watch: {
    openMarker(newMarker, oldMarker) {
      if (oldMarker) {
        this.markerChange = true;
      }
      if (!newMarker) {
        this.markerChange = false;
      }
    },
    markers() {
      const regionItem = Object.values(regions).find((item) => item.title === this.region);
      if (regionItem) {
        this.position = regionItem?.position;
        this.zoomLevel = 4;
      } else {
        this.updateCenter();
      }
    }
  },
  emits: ['popover-click'],

});
